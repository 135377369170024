@import url('https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,600,700,800,900&display=swap');

.outer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: #576EE3;
  transition: 1s;
}

.slide {
  background: #1DBED6;
}

.container {
  position: relative;
  width: 800px;
  height: 400px;
  background: rgba(0,0,0,0.125);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

.container .boxC {
  position: relative;
  width: 50%;
  height: 100%;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 40px;
}

.container .boxC h2 {
  color: #fff;
  font-size: 1.2em;
  font-weight: 500;
  margin-bottom: 10px;
}

.container .boxC button {
  cursor: pointer;
  padding: 10px 20px;
  background: #fff;
  color: #333;
  font-size: 16px;
  font-weight: 500;
  border: none;
  outline: none;
}

.formBx {
  position: absolute;
  left: 50px;
  width: 350px;
  height: 480px;
  background: #fff;
  z-index: 1000;
  box-shadow: 0 5px 25px rgba(0,0,0,0.15);
  transition: 0.5s;
  transition-delay: 0.5s;
  overflow: hidden;
}

.slide .formBx {
  left: 400px;
}

.formBx .form {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s;
  background: #fff;
}

.formBx .form.signupform {
  top: 100%;
  transition-delay: 0s;
}
.slide .formBx .form.signupform {
  top: 0;
  transition-delay: 1s;
}

.formBx .form.signinform {
  top: 0;
  transition-delay: 1s;
}
.slide .formBx .form.signinform {
  top: -100%;
  transition-delay: 0s;
}

.formBx .form form {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 50px;
}

.formBx .form form h3 {
  font-size: 1.4em;
  color: #333;
  margin-bottom: 20px;
  font-weight: 600;
}

.formBx .form form input {
  width: 100%;
  margin-bottom: 15px;
  padding: 10px;
  outline: none;
  font-size: 0.8em;
  border: 1px solid #333;
  letter-spacing: 0.1em;
}

.formBx .form form input[type="submit"] {
  background: #576EE3;
  border: none;
  color: #fff;
  max-width: 100px;
  cursor: pointer;
  font-weight: 500;
}

.formBx .signupform form input[type="submit"] {
  background: #1DBED6;
}

.check {
    color: green;
    margin-right: 0.25rem;
}
.circle {
    color: red;
    margin-right: 0.25rem;
}

.requirements-list {
    list-style: none;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding-left: 1rem;
    margin-top: 0;
}

.password-content {
    font-size: 0.8em;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    opacity: 0;
    max-height: 0px;
    overflow: hidden;
    transition: all 1s ease;
}

.password-content.show {
  opacity: 1;
  max-height: 500px;
}

.password-requirements-header {
  margin: 0;
  padding: 0;
}

.checkbox-container {
  display: flex;
  flex-direction: column; 
}

.individual-checkbox-container {
  display: flex;
  align-items: center; 
}

.individual-checkbox-container input[type="checkbox"] {
  margin: 0; 
  padding: 0; 
  display: inline-block; 
}

.individual-checkbox-container span { 
  font-size: 0.9em; 
}

.input-checkbox1 {
  margin-left: 10px;
  margin-right: 5px;
  margin-bottom: 0px;
  align-items: center;
}

.input-checkbox2 {
  margin-left: 10px;
  margin-right: 5px;
  margin-bottom: 25px;
  align-items: center;
}

.label-container {
  padding-bottom: 12px;
}

.policy-link {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

.consent-statement {
  font-size: 0.7rem;
  margin-bottom: 5%;
  margin-left: 5%;
}