
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

body {
    margin: 0; /* Remove default margin */
    padding: 0; /* Remove default padding */
    overflow: hidden;
  }
  
.dashboard {
    background: #f5f5f5;
    display: flex;
    width: 100%;
    height: 100%;
    flex: 1;
    overflow: auto;
  }

  .dashboard-content {
    flex-wrap: wrap;
    justify-content: space-evenly;
    display: flex;
    height: 100vh;
    width: 100%;
    flex: .95;
  }
  
  /* Link to access FullCalendar: https://www.npmjs.com/package/@fullcalendar/react */
  .box1 {
    margin-top: 2rem;
    border-radius: 10px;
    margin-bottom: 2rem;
    width: 45%;
    height: 40%;
    align-items: center;
    background-color: white;
    border: 1px solid rgb(0, 0, 0);
    overflow: hidden; /* Prevent scrolling */
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center content vertically */
  }
 
  .box2 {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    border-radius: 10px;
    width: 50%;
    height: 40%;
    background-color: white;
    border: 1px solid rgb(0, 0, 0);
    overflow: auto; 
    z-index: 1;
    margin-bottom: 2rem;
  }

  .box3 {
    margin-bottom: 2rem;
    border-radius: 10px;
    width: 45%;
    height: 45%;
    align-items: center;
    background-color: white;
    border: 1px solid rgb(0, 0, 0);
    overflow: auto;
  }

  .box4 {
    margin-bottom: 2rem;
    border-radius: 10px;
    width: 50%;
    height: 45%;
    align-items: center;
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(0, 0, 0);
    overflow: hidden;
  }

  .box1, .box2, .box3, .box4 {
    box-shadow: 0px 4px 6px rgba(0, 0, 0, .1);
    transition: box-shadow 0.3s ease;
}

  .box1:hover, .box2:hover, .box3:hover, .box4:hover {
    box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.7);
  }


  .comments-dashboard-container {
    background: transparent;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    font-family: 'Inter', ui-sans-serif, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  }

  .comments-title {
    font-weight: bolder;
    color: #000000;
    margin-left: 1.5rem;
    padding: 0;
    /* text-shadow: 2px 2px 4px rgba(0,0,0,0.3); */
    margin-top: 1rem;
  }
  .replies-header {
    font-weight: bolder;
    color: #000000;
    margin-left: 1.5rem;
    padding: 0;
    text-shadow: 2px 2px 4px rgba(0,0,0,0.3);
    background: none;
  }

  .all-comments-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;
  }

  .comment-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 90%;
    margin-top: 0.5rem;
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); */
    
    border-radius: 10px;
  }

  .comment-container.Instagram {
    box-shadow: 0 0 8px rgba(225, 48, 108, 0.8);
  }
  .comment-container.YouTube {
    box-shadow: 0 0 8px rgba(225, 0, 0, 0.8);
  }

  .comment-platform {
    margin-left: 3%;
  }

  .comment-username {
    color: black;
    font-weight: 700;
    margin-left: 2%;
    margin-right: 20%;
  }

  .comment-date {
    margin-left: auto;
    margin-right: 1.5rem;
  }

  .comment-text {
    margin-left: 1.5rem;
    margin-right: 1rem;
  }

  /* .reply-button {
    background: #e0e0e0;
    border: none;
    padding: 0.5rem 1rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    margin-left: auto;
    margin-right: 1.5rem;
    transition: all 0.3s ease-in-out;
    border-radius: 5px;
    color: black;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }
  .reply-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.6);
  } */



  /* .reply-button {
    background: #f5f5f5; 
    border: 1px solid #d1d1d1; 
    padding: 0.5rem 1.25rem; 
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    margin-left: auto;
    margin-right: 1.5rem;
    transition: all 0.3s ease-in-out;
    border-radius: 10px; 
    color: #333; 
    font-size: 0.9rem; 
    font-weight: 500; 
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); 
    cursor: pointer;
  }
  
  .reply-button:hover {
    background: #e0e0e0; 
    transform: translateY(-3px); 
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2); 
  }
  
  .reply-button:active {
    transform: translateY(0); 
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
    background: #d6d6d6; 
  }
   */

   .reply-button {
    background: #007BFF; /* Vibrant blue for a professional look */
    border: 1px solid #007BFF; /* Match the border color with the background */
    padding: 0.5rem 1.25rem; /* Comfortable padding */
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    margin-left: auto;
    margin-right: 1.5rem;
    transition: all 0.3s ease-in-out;
    border-radius: 10px; /* Rounded corners for a modern style */
    color: white; /* White text for contrast */
    font-size: 0.9rem; /* Balanced font size */
    font-weight: 500; /* Medium weight for clarity */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    cursor: pointer;
  }
  
  .reply-button:hover {
    background: #3e70a6; /* Darker blue for hover effect */
    border-color: #0056b3; /* Adjust border to match the hover background */
    transform: translateY(-2px); /* Lift effect */
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2); /* Enhance shadow on hover */
  }
  
  .reply-button:active {
    background: #004085; /* Even darker blue for active state */
    border-color: #004085; /* Match border to active background */
    transform: translateY(0); /* Reset transform on click */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow on click */
  }
  


  /* Styling reply input */
  .reply-input {
    width: 80%;
    border-radius: 20px;
    transition: all ease-in-out .5s;
    padding: 0.7rem 1rem;
    /* border: 2px solid #8179ff; */
    border: 2px solid #007BFF;
    background-color: rgba(255, 255, 255, 0.8);
    margin-bottom: 1rem;
  }
  .reply-input:focus {
    outline: none;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0 0 10px rgba(255, 224, 130, 0.5);
  }

  .platform-name-date-container {
    display: flex;
    flex-direction: row;
    /* margin-left: 1.5rem; */
    /* margin-top: 1rem;  */
  }

  .all-replies-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .reply-comment-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 1.5rem;
    width: 85%;
    margin-top: 0.5rem;
    box-shadow: 0 0 10px rgba(225, 48, 108, 0.8);
    border-radius: 10px;
  }

  .reply-input-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .send-button {
    position: relative;
    transform: translate(-175%, -50%);
    cursor: pointer;
  }

  .replies-divider {
    border: 0;
    line-height: 1em;
    position: relative;
    text-align: center;
    height: 1.5em;
    font-size: 1rem;
    width: 95%;
  }
  
  .replies-divider::before {
    content: "";
    background: linear-gradient(to right, transparent, black, transparent);
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 1px;
  }
  
  .replies-divider::after {
    content: attr(data-content);
    position: relative;
    padding: 0 7px;
    line-height: 1.5em;
    color: black;
    background-color: white;
  }