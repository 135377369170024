
.pp-container {
    display: flex;                     
    flex-direction: column;          
    height: 100vh;                     
    width: 100vw;                      
}

.pp-header {
    color: white;                     
    background-color: hsl(200, 100%, 6%, 0.8); 
    padding: 10px;                    
    text-align: center;               
    font-size: 24px;                  
}

.pp-content {
    flex: 1;                          
    overflow-y: auto;                
    padding: 20px;                    
    background-color: #fff;          
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); 
}

.pp-footer {
    padding-top: 3%;                 
    background-color: hsl(200, 100%, 6%, 0.8); 
    color: white;                    
    text-align: center;          
    height: 10vh;
}
