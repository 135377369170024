.post-container {
    background: #f5f5f5;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.next-button-container {
    margin-top: 1.5rem;
    align-self: flex-end;
    margin-left: 60%;
}

.next-button-container-2 {
    margin-top: 1.5rem;
    align-self: flex-end;
}

.buttons-container {
    width: 100%;
    justify-content: center;
    display: flex;
    flex-direction: row;
    margin-bottom: 1.5rem;
    margin-top: 1rem;
}

.slide-enter {
    transform: translateX(100%);
    opacity: 0;
}

.slide-enter-active {
    transform: translateX(0);
    opacity: 1;
    transition: transform 0.5s ease, opacity 0.5s ease;
}

.slide-exit {
    transform: translateX(0);
    opacity: 1;
}

.slide-exit-active {
    transform: translateX(-100%);
    opacity: 0;
    transition: transform 0.5s ease, opacity 0.5s ease;
}

.caption-input {
    width: 50%;
    height: 50%;
}
  
.new-post-header {
    text-align: center;
    color: #333;
    font-family: Arial, sans-serif;
}

.input-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 50%;
    height: 50%;
}

label {
    font-size: 14px;
    color: #666;
}

.styled-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 14px;
    background-color: #fff;
}

.styled-input:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.3);
}

textarea.styled-input {
    height: 100px;
    resize: none;
}
  
.back-button-container {
    margin-top: 1.5rem;
    align-self: flex-end;
    margin-right: 45%;
}

.social-media-selector {
    padding: 20px;
    background-color: #f4f4f9;
    max-width: 600px;
    margin: 0 auto;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  h2 {
    text-align: center;
    font-family: Arial, sans-serif;
    color: #333;
  }
  
  .platform-options {
    display: flex;
    justify-content: space-around;
    margin-bottom: 3rem;
  }
  
  .platform-option {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1.5rem;
    width: 10rem;
    height: 10rem;
    border: 2px solid transparent;
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.3s ease;
    box-sizing: border-box;
  }
  
  .platform-option:hover {
    background-color: #e6e6e6;
    transform: scale(1.1);
  }
  
  .platform-option.selected {
    border-color: #007bff;
    background-color: #e6f0ff;
  }
  
  .platform-option svg {
    font-size: 3rem;
    margin-bottom: 10px;
  }
  
  .platform-option span {
    font-size: 1.2rem;
    color: #333;
  }
  
  .selected-platforms {
    margin-top: 30px;
  }
  
  .selected-platforms h3 {
    text-align: center;
    font-family: Arial, sans-serif;
    color: #333;
  }
  
  .selected-platforms ul {
    list-style-type: none;
    padding: 0;
    text-align: center;
  }
  
.selected-platforms li {
  margin: 5px 0;
  font-weight: bold;
}

.post-content-container {
  width: 100%;
  height: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-grow: 1;
}

.platform-name-icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.platform-option.Instagram {
  background-color: #ff8bc5;
}

.platform-option.TikTok {
  background-color: #69C9D0;
}

.platform-option.YouTube {
  background-color: white;
}

.platform-option.X {
  background-color: #26a7de;
}

.platform-options-container {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.post-content-header {
  margin-bottom: 4rem;
}

.progress-bar-container {
  align-self: stretch;
  margin-bottom: 2.5rem;
}

.post-bottom-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}