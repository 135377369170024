html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden; 
}

.analytics-container { 
  width: 100%;
  height: 100vh; 
  display: flex;
  flex-direction: column;
  padding: 2rem;
  background-color: #f3f3f4;
  font-family: 'Inter', sans-serif;
}

.analytics-header {
  text-align: left;
  margin-bottom: 1rem;
  flex-shrink: 0; 
}

.analytics-header h1 {
  font-size: 2rem;
  font-weight: bold;
  margin: 0;
}

.ap-social-media-platforms {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding-top: 10px;
  flex-shrink: 0; 
}

.ap-platform-card {
  background-color: #fff;
  border-radius: 15px;
  text-align: center;
  width: 24%;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  overflow: hidden;
  height: 50px;
  border: 1px solid black;
}

.ap-platform-card:hover {
  transform: scale(1.02); 
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.2); 
  background-color: #f5f5f5; 
  border: 1.5px solid black;
}

.ap-platform-card:focus {
  outline: none;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);
}

.ap-platform-card.selected {
  border: 2px solid #4f52ff; 
  border-radius: 15px;
  background-color: #e6eaff; 
  overflow: hidden;
}

.analytics-charts {
  margin-bottom: 1rem;
}

.nonHeader {
  flex-grow: 1;
  overflow-y: auto; 
  overflow-x: hidden; 
  scrollbar-width: none;
  padding-bottom: 80px;
}

.profile-container {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden; 
  flex-direction: row;
}

.pc-platform-icon {
  display: flex;
  width: 20%;
  height: 50px;
  align-items: center;
  justify-content: center;
  line-height: 0;
}

.ap-platform-card.disconnected {
  background-color: #e0e0e0; 
  color: #6b6b6b; 
  box-shadow: none; 
  position: relative; 
}

.ap-platform-card.disconnected:before { 
  position: absolute;
  display: flex; 
  justify-content: center; 
  align-items: center; 
  top: 50%;
  left: 50%; 
  transform: translate(-50%, -50%);
  font-size: 1.2rem; 
  color: #ffffff; 
  background-color: rgba(0, 0, 0, 0.4); 
  padding: 0.5rem 1rem;
  border-radius: 8px;
  z-index: 1; 
  height: 100%;
  width: 100%;
}

.ap-platform-card.disconnected:hover {
  transform: scale(1.02); 
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.2); 
  background-color: #f5f5f5; 
  border: 1.5px solid black;
  font-weight: bold;
}

.ap-connect-platform-button {
  width: 100%;
  height: 100%;
  cursor: pointer;
  border: none;
  background-color: #e0e0e0;
  font-size: medium;
  display: flex;
  align-items: center;
}

.ap-platform-card.disconnected:hover .ap-connect-platform-button {
  font-weight: bold;
}

.ap-connect-platform-button:disabled {
  background-color: #a0a0a0;
  color: #d0d0d0; 
  box-shadow: none;
  transform: none;
}