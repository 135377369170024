.cta {
    display: flex;
    padding: 11px 33px;
    text-decoration: none;
    font-size: 25px;
    color: white;
    background: #6225e6;
    transition: 1s;
    box-shadow: 6px 6px 0 black;
    transform: skewX(-15deg);
    border: none;
    cursor: pointer;
    opacity: 0;
    transition: 0.5s ease-in-out all;
}

.cta.show {
    opacity: 1;
}
  
.cta:focus {
    outline: none;
}
  
.cta:hover {
    transition: 0.5s;
    box-shadow: 10px 10px 0 #fbc638;
}
  
  .cta .second {
    transition: 0.5s;
    margin-right: 0px;
  }
  
  .cta:hover .second {
    transition: 0.5s;
    margin-right: 45px;
  }
  
  .span {
    transform: skewX(15deg);
  }
  
  .second {
    width: 20px;
    margin-left: 30px;
    position: relative;
    top: 12%;
  }
  
  .one {
    transition: 0.4s;
    transform: translateX(-60%);
  }
  
  .two {
    transition: 0.5s;
    transform: translateX(-30%);
  }
  
  .cta:hover .three {
    animation: color_anim 1s infinite 0.2s;
  }
  
  .cta:hover .one {
    transform: translateX(0%);
    animation: color_anim 1s infinite 0.6s;
  }
  
  .cta:hover .two {
    transform: translateX(0%);
    animation: color_anim 1s infinite 0.4s;
  }
  
  @keyframes color_anim {
    0% {
      fill: white;
    }
  
    50% {
      fill: #fbc638;
    }
  
    100% {
      fill: white;
    }
  }
  