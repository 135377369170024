.calendar-page-container {
  display: flex;
  align-items: flex-start;
  margin-left: 5px;
  margin-right: 2rem;
  width: 97%;
}
  
.cp-feed-container {
  width: 100%;
  display: grid;
  justify-content: space-around;
  grid-template-columns: 32% 32% 32%;
  border-color: rgb(64, 69, 224);
  margin-left: 0.5%;
  border-width: thick;
  max-height: 80vh;
  max-width: 130vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

/* Custom scrollbar styling */
.cp-feed-container::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

.cp-feed-container::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.1); /* Light background for scrollbar track */
}

/* Calendar event icon styling */
.fc .fc-event {
  display: flex !important;
  align-items: center !important;
  flex-direction: row;
}

.fc .fc-event .calendar-icon {
  width: 8px;
  height: 8px;
  flex-shrink: 0;
}

.fc .fc-event .instagram-icon {
  color: #E1306C;
}

.fc .fc-event .tiktok-icon {
  color: #000000;
}

.fc .fc-event .youtube-icon {
  color: #FF0000;
}

.fc .fc-event {
  display: inline-flex !important;
  align-items: center !important;
  flex-direction: row;
}

.fc .fc-event-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1;
}

.cp-feed-container::-webkit-scrollbar-track:hover {
  background: rgba(115, 115, 115, 0.1); /* Light background for scrollbar track */
}

.cp-feed-container::-webkit-scrollbar-thumb {
  background-color: rgb(255, 255, 255, 0.1); /* Slightly transparent scrollbar thumb */
  border-radius: 10px; /* Rounded edges */
}

.cp-feed-container::-webkit-scrollbar-thumb:hover {
  background-color: rgba(140, 140, 140, 0.8); /* Darker on hover */
}



.cp-post-container {
  width: 92%;
  background-color: #ffffff; 
  border: 2px solid #ddd;  
  border-radius: 10px; 
  font-size: large;
  font-weight: bold;
  margin-top: 20px; 
  margin-left: 0px;
  margin-bottom: 0px;
  padding: 10px 5px; 
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1); 
  transition: transform 0.2s ease-in-out; 
  display: flex;
  flex-direction: column;
}

.cp-post-caption {
  margin: 10px 10px 10px;
  font-size: 12px;
  color: #333;
  font-weight: normal;
  line-height: 1.4;
  background-color: #ffffff;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.cp-post-media {
  width: 88%;
  min-height: 350px;
  margin-left: 5%;
}

.cp-post-analytics {
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid #eee;
}

.cp-post-video-container {
  width: 100%;
  margin-bottom: 10px;
}

.cp-post-video {
  width: 100%;
  max-height: 300px;
  object-fit: contain;
}

.cp-post-stats-container {
  display:flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-left: 0px;
}

.cp-post-container:hover {
  transform: scale(1.02); 
  /* border-color: #aaa;  */
  background-color: #f1f1f1; 
}

.cp-post-mini-container {
  height: 50px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.cp-right-side-container {
  height: 100vh;
  width: 30%;
  margin-right: 0%;
 
}


.cp-calendar-container {
  height: 48%;
  width: 90%;
  margin-top: 22%;
  margin-right: 20px;
  margin-left: 5%;
  border-radius: 10px;
  background-color: white;
  border: 2px solid rgb(0, 0, 0);
  overflow: hidden;
}
.cp-time-picker-container {
  margin-top: 5%;
  margin-left: 3%;
  margin-right: 7%;
  background-color: white;
  color: black;
}

.cp-platforms-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
  margin-right: 15px;
  margin-left: 5px;
}
.cp-header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: black;
  font-size: large;
  height: 1px;
}
.cp-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: black;
  background-color: white;
  border: 2px solid #e8e8e8;
  border-radius: 8px;
  padding: 12px 24px;
  transition: all 0.3s ease;
  cursor: pointer;
  font-size: large;
  margin-top: 5%;
  margin-left: 28%;
}

.cp-button:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  background-color: #f8f8f8;
  border-color: #d0d0d0;
}
.cp-button-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
  margin-right: 27px;
  margin-left: 8px;
  color: black;
  background-color: white;
  border: none;

}
.cp-button-container:hover {
  transform: scale(1.1); 
  box-shadow: #aaa;
}

/* Highlight selected date */
.fc-day-selected {
  background-color: rgba(0, 123, 255, 0.3) !important;
  border: 2px solid #007bff !important;
  box-shadow: 0 0 15px rgba(0, 123, 255, 0.4) !important;
  transition: all 0.3s ease-in-out;
  transform: scale(1.05);
  z-index: 1;
}

.fc-day:hover {
  background-color: rgba(0, 123, 255, 0.1) !important;
  cursor: pointer;
  transform: scale(1.02);
  transition: all 0.2s ease-in-out;
}



.cp-header-and-feed-container {
  display: flex;
  flex-direction: column;
  width: 70%;
}
  
.social-media-platforms {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding-top: 10px;
  flex-shrink: 0; 
}

.cp-platform-card {
  background-color: #fff;
  border-radius: 8px;
  padding: 1rem;
  text-align: center;
  width: 21%;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.cp-platform-card.selected {
  border: 2px solid #4f52ff; 
  background-color: #e6eaff; 
}

.page-container {
  display: flex;
  gap: 24px;
  padding: 24px;
  height: calc(100vh - 48px);
}

.feed-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 800px;
}

.platform-selector {
  display: flex;
  gap: 16px;
  padding: 16px;
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.platform-card {
  padding: 12px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
  background: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.platform-card.selected {
  transform: scale(1.05);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.posts-feed {
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-right: 16px;
}

.post-summary {
  background: white;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.post-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.post-date {
  color: #666;
  font-size: 14px;
}

.post-content {
  margin-bottom: 16px;
}

.post-content img {
  width: 100%;
  border-radius: 8px;
  margin-bottom: 12px;
}

.video-placeholder {
  background: #f5f5f5;
  border-radius: 8px;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  color: #666;
}

.post-caption {
  font-size: 14px;
  line-height: 1.5;
  color: #333;
}

.post-stats {
  display: flex;
  justify-content: space-around;
  padding-top: 16px;
  border-top: 1px solid #eee;
}

.stat-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
}

.stat-value {
  font-weight: 600;
  font-size: 16px;
}

.stat-label {
  font-size: 12px;
  color: #666;
}

.calendar-section {
  width: 400px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.calendar-wrapper {
  background: white;
  border-radius: 12px;
  padding: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: calc(100% - 100px);
}

.create-post-button {
  padding: 12px 24px;
  background: #007bff;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  transition: all 0.2s ease;
}

.create-post-button:hover {
  background: #0056b3;
  transform: translateY(-1px);
}

.fc {
  height: 100% !important;
}

.fc-daygrid-day-events {
  display: flex !important;
  flex-direction: row !important;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2px;
}

.fc-event {
  margin: 1px 2px !important;
  display: inline-flex !important;
  background: transparent !important;
  border: none !important;
}

.fc-daygrid-event-harness {
  display: inline-block !important;
  margin-right: 2px !important;
}

.fc-daygrid-day.selected {
  background-color: #e6eaff; 
  border: 2px solid var(--primary-color);
  color: var(--primary-color);
}
