.feedContainer {
  position: relative;
  width: 30%;
  height: 100%;
  display:flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: hidden;
  margin: 0.5rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  overflow-x: hidden;
}

.sourceText {
  margin: 0; 
  margin-top: 0.25rem;
  font-family: 'Futura';
  font-weight: 600;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
}

.caption {
  position: sticky;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 5px;
  box-sizing: border-box;
  pointer-events: none;
  z-index: 999;
}


.captionButton {
  font-size: 12px;
  padding: 5px;
  margin-top: 5px;
  background-color: #f4f4f4;
  border: none;
  cursor: pointer;
}

.recentposts-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}
  
.img {
  width: 100%;
  height: auto;
  transition: all 100ms ease-out;
}

.caption:hover {
  transform: scale(1.24);
}